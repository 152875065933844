



















































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class extends Vue {
  @Prop({ type: String })
  public type!: String;
}
