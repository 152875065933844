import Vue, { DirectiveOptions } from 'vue';

const directive: DirectiveOptions = {
  inserted: (el, binding, vnode): boolean|void => {
    el.addEventListener('keydown', (ev: KeyboardEvent) => {
      // We also allow child elements to target this handler
      if (ev.key !== 'Enter' && ev.key !== 'Tab') {
        return undefined;
      }

      // Allow TAB-bing backwards and shift-enter (for textarea)
      if (ev.shiftKey) {
        return undefined;
      }

      try {
        const refNames = Array.isArray(binding.value)
          ? binding.value
          : [binding.value];

        const targetRefName = refNames.find((refName) => {
          if (typeof refName === 'string') {
            const $refCandidate = vnode.context?.$refs[refName];
            if ($refCandidate !== null && $refCandidate !== undefined) {
              if ((<any>$refCandidate).disabled === true) {
                return false;
              }
              return true;
            }
          }

          return false;
        });

        const $ref = targetRefName
          ? vnode.context?.$refs[targetRefName]
          : undefined;

        if ($ref !== undefined && $ref !== null) {
          (<HTMLElement>$ref).focus();
        } else {
          const target = ev.target as HTMLElement;

          // First try to find a container, otherwise look for a form
          const container = target.closest('.focus-next-on-enter-container') ?? target.closest('form');
          const formControls = container?.querySelectorAll('input, select, textarea, button');

          if (formControls === undefined) {
            return undefined;
          }

          let idx = 0;
          for (; idx < formControls.length; idx += 1) {
            if (formControls[idx] === ev.target) {
              idx += 1;
              break;
            }
          }

          // Next let's find the first non-disabled element
          for (; idx < formControls.length; idx += 1) {
            if ((<any>formControls[idx]).disabled === false) {
              break;
            }
          }

          if (idx >= formControls.length) {
            return undefined;
          }

          (formControls[idx] as HTMLElement).focus();
        }

        ev.preventDefault();
        return false;
      } catch (ex) {
        // Just do nothing if we can't refocus, it's not a big deal
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console
          console.error(ex);
        }

        return undefined;
      }
    });
  },
};

// Name is a bit misleading as we're also using the same feature for TAB for now, which
// might change in the future as it's not really required to handle that one
Vue.directive('focus-next-on-enter', directive);
