













import Vue from 'vue';
import Component from 'vue-class-component';

import { toasts } from '@/utils/toasts';
import httpClient from '@/services/http-client';

const publicLayout = 'public';
const defaultLayout = 'main';

@Component({
  name: 'App',
})
export default class extends Vue {
  get key(): string {
    return this.$route.path;
  }

  get isLoggedIn() {
    return this.$store.state.isLoggedIn;
  }

  get layout(): string {
    if (this.$route.name === 'notFound') {
      return this.isLoggedIn ? `${defaultLayout}-layout` : `${publicLayout}-layout`;
    }
    return `${this.$route.meta?.layout || defaultLayout}-layout`;
  }

  created(): void {
    toasts.observable
      .subscribe(([message, options]) => {
        if (options && options.id) {
          this.$bvToast.hide(options.id);
        }
        this.$bvToast.toast(message, options);
      });

    // eslint-disable-next-line no-async-promise-executor
    httpClient.interceptors.response.use(
      undefined,
      async (err) => {
        // eslint-disable-next-line no-underscore-dangle
        if (err.status === 401 && (!err.config || !err.config.__isRetryRequest)) {
          await this.$store.dispatch('performLogout')
            // Plug any exception being thrown with a "catch" continuation
            .catch((logoutErr) => {
              // we can't do anything about a failed logout, really, so just log it to the console for now
              // eslint-disable-next-line no-console
              console.error(logoutErr);
            });
          this.$router.push('/login');
        }

        return Promise.reject(err);
      },
    );
  }
}
