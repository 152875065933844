const Cookies = require('js-cookie');
const axios = require('axios');

const instance = axios.create({
  // Set a hefty default timeout
  timeout: 5000,
});

instance.interceptors.request.use((config) => {
  const csrfCookie = Cookies.get('XSRF-TOKEN');
  if (csrfCookie) {
    // eslint-disable-next-line no-param-reassign
    config.headers.common['X-XSRF-TOKEN'] = csrfCookie;
  }

  return config;
});

module.exports = instance;
