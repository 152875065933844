<template>
  <b-container fluid />
</template>

<script>
import { mapState, mapActions } from 'vuex';

import { toasts } from '@/utils/toasts';

export default {
  created() {
    this.logout();
  },
  computed: {
    ...mapState(['isLoggedIn']),
  },
  methods: {
    ...mapActions([
      'performLogout',
    ]),
    async logout() {
      const logoutResult = await this.performLogout();
      if (logoutResult) {
        toasts.success('Deconectare reușită!');
        this.$router.push('/login');
      }
    },
  },
};
</script>
