export class Constants {
  public MRN = 'MRN'
  public GRN = 'GRN'
  public BCO = 'BCO'
  public BTO = 'BTO'

  public inventoryOrderCriteria = {
    denumire: {
      value: 0,
      name: 'Denumire',
    },
    codProdus: {
      value: 1,
      name: 'Cod Produs',
    },
  }

  public vatPercentageOptions = [
    { label: '19 %', key: 0.19 },
    { label: '9 %', key: 0.09 },
    { label: '5 %', key: 0.05 },
    { label: '0 %', key: 0.0 },
  ]

  public productCatalogModalState = {
    view: 1,
    edit: 2,
    add: 3,
    addExternal: 4,
  }

  productClassTypes = {
    product: 'PDT',
    service: 'SVC',
  }

  userAccountTypes = {
    admin: 'InvAppAdmin',
    user: 'InvAppUser',
  }

  public productCategories = [
    { key: 301, account: 301, label: 'Materii prime' },
    { key: 3021, account: 3021, label: 'Materiale auxiliare' },
    { key: 3022, account: 3022, label: 'Combustibili' },
    { key: 3023, account: 3023, label: 'Materiale pentru ambalat' },
    { key: 3024, account: 3024, label: 'Piese de schimb' },
    { key: 3025, account: 3025, label: 'Seminţe şi materiale de plantat' },
    { key: 3026, account: 3026, label: 'Furaje' },
    { key: 3028, account: 3028, label: 'Alte materiale consumabile' },
    { key: 303, account: 303, label: 'Materiale de natura obiectelor de inventar' },
    { key: 341, account: 341, label: 'Semifabricate' },
    { key: 345, account: 345, label: 'Produse finite' },
    { key: 346, account: 346, label: 'Produse reziduale' },
    { key: 347, account: 347, label: 'Produse agricole' },
    { key: 371, account: 371, label: 'Mărfuri' },
    { key: 381, account: 381, label: 'Ambalaje' },
  ]

  public serviceCategories = [
    { key: 601, account: 601, label: 'Cheltuieli cu materiile prime' },
    { key: 602, account: 602, label: 'Cheltuieli cu materialele consumabile' },
    { key: 6021, account: 6021, label: 'Cheltuieli cu materialele auxiliare' },
    { key: 6022, account: 6022, label: 'Cheltuieli privind combustibilii' },
    { key: 6023, account: 6023, label: 'Cheltuieli privind materialele pentru ambalat' },
    { key: 6024, account: 6024, label: 'Cheltuieli privind piesele de schimb' },
    { key: 6025, account: 6025, label: 'Cheltuieli privind seminţele şi materialele de plantat' },
    { key: 6026, account: 6026, label: 'Cheltuieli privind furajele' },
    { key: 6028, account: 6028, label: 'Cheltuieli privind alte materiale consumabile' },
    { key: 603, account: 603, label: 'Cheltuieli privind materialele de natura obiectelor de inventar' },
    { key: 604, account: 604, label: 'Cheltuieli privind materialele nestocate' },
    { key: 605, account: 605, label: 'Cheltuieli privind energia şi apa' },
    { key: 606, account: 606, label: 'Cheltuieli privind activele biologice de natura stocurilor' },
    { key: 607, account: 607, label: 'Cheltuieli privind mărfurile' },
    { key: 608, account: 608, label: 'Cheltuieli privind ambalajele' },
    { key: 7015, account: 7015, label: 'Venituri din vânzarea produselor finite' },
    { key: 7017, account: 7017, label: 'Venituri din vânzarea produselor agricole' },
    { key: 7018, account: 7018, label: 'Venituri din vânzarea activelor biologice de natura stocurilor' },
    { key: 702, account: 702, label: 'Venituri din vânzarea semifabricatelor' },
    { key: 703, account: 703, label: 'Venituri din vânzarea produselor reziduale' },
    { key: 704, account: 704, label: 'Venituri din servicii prestate' },
    { key: 705, account: 705, label: 'Venituri din studii şi cercetări' },
    { key: 706, account: 706, label: 'Venituri din redevenţe, locaţii de gestiune şi chirii' },
    { key: 707, account: 707, label: 'Venituri din vânzarea mărfurilor' },
    { key: 708, account: 708, label: 'Venituri din activităţi diverse' },
  ]
}

export const constants = new Constants();
